
import './App.css';
import './Video';
import Video from './Video';

function App() {
  return (
    <div className="app">
      <h1>Build Tik tok Clone</h1>
      <div className='app__videos'>
        <Video 
          url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
          channel="kamleshmathe"
          description="Tiktok clone"
          song="wow tiktok clone clone clone"
          shares={100}
          likes={200}
          messages={300}
          />
          <Video 
          url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
          channel="kamleshmathe"
          description="Tiktok clone"
          song="wow tiktok clone clone clone"
          shares={100}
          likes={200}
          messages={300}
          />
        
      </div>     
      
    </div>
  );
}

export default App;
