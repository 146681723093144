import React, { useRef, useState } from 'react';
import './video.css';
import VideoFooter from './VideoFooter';
import VideoSidebar from './VideoSidebar';
const Video = ({url,channel,description,song,likes,shares,messages}) => {
    const [playing,setPlaying] = useState(false);
    const videoRef = useRef(null);

    const handleVideoPress = ()=>{
        if(playing){
            videoRef.current.pause();
            setPlaying(false);
        }else{
            videoRef.current.play();
            setPlaying(true);
        }
    }
    return ( 
        <div className="video">
            <video 
            src={url}
            className="video__player"
            ref={videoRef}
            onClick={handleVideoPress}
            >

            </video>
            <VideoFooter channel={channel} description={description} song={song}/>
            <VideoSidebar likes={likes} messages={messages} shares={shares}/>
        </div>
     );
}
 
export default Video;