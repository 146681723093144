import React from 'react';
import './VideoFooter.css';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import Ticker from 'react-ticker';
const VideoFooter = ({channel,description,song}) => {
    return ( 
    <div className='videoFooter'>
        <div className="videoFooter__text">
            <h3>@{channel}</h3>
            <p>{description}</p>
            <div className="videoFooter__ticker">
                <MusicNoteIcon className="videoFooter__icon" />
                <Ticker 
                mode="smooth"
                >
                    {({index}) => (
                        <>
                        <p>{song}</p>
                        </>
                    )}
                </Ticker>
            </div>
        </div>
        <img className="videoFooter__record"
        src="https://cdn.pixabay.com/photo/2012/04/15/18/57/dvd-34919_960_720.png"
        alt=""
        />
    </div> );
}
 
export default VideoFooter;